import {  Routes } from '@angular/router';

import { LandingComponent } from './pages/landing/landing.component';
import { ConsultingPageComponent } from './pages/consulting/consulting.component';
import { ProductPageComponent } from './pages/product/product.component';
import { FeedbackCannyComponent } from './pages/feedback/feedback-canny.component';

export const HOME_ROUTES: Routes = [
  { path: '', component: LandingComponent },
  { path: 'about', component: LandingComponent },
  { path: 'consulting', component: ConsultingPageComponent },
  { path: 'product', component: ProductPageComponent },

  // Feedback
  {
    path: 'feedback',
    component: FeedbackCannyComponent,
    children: [{
      path: '*',
      component: FeedbackCannyComponent
    }]
  },

  // Legal
  {
    path: 'legal',
    loadChildren: () => import('./legal/legal.module').then(m => m.LegalModule)
  }
];
