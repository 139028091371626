<h2>Markets</h2>
<mat-tab-group class="markets" mat-stretch-tabs="false" mat-align-tabs="start">
  <mat-tab [label]="market.name" *ngFor="let market of markets" >
    <mat-tab-group class="categories" mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab [label]="category.name" *ngFor="let category of market.categories">
        <table mat-table [dataSource]="category.tickers" class="mat-elevation-z8">

          <ng-container matColumnDef="symbol" sticky>
            <th mat-header-cell *matHeaderCellDef>Symbol</th>
            <td mat-cell *matCellDef="let element">
              {{element.symbol}}
            </td>
          </ng-container>

          <ng-container matColumnDef="name" sticky>
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">{{element.name}}</td>
          </ng-container>

          <ng-container matColumnDef="last">
            <th mat-header-cell *matHeaderCellDef>Last</th>
            <td mat-cell *matCellDef="let element">{{element.last}}</td>
          </ng-container>

          <ng-container matColumnDef="high">
            <th mat-header-cell *matHeaderCellDef>High</th>
            <td mat-cell *matCellDef="let element">{{element.high}}</td>
          </ng-container>

          <ng-container matColumnDef="low">
            <th mat-header-cell *matHeaderCellDef>Low</th>
            <td mat-cell *matCellDef="let element">{{element.low}}</td>
          </ng-container>

          <ng-container matColumnDef="change">
            <th mat-header-cell *matHeaderCellDef>Chg.</th>
            <td mat-cell *matCellDef="let element" [class]="{positive: element.change > 0, negative: element.change < 0}">
              <span *ngIf="element.change > 0">+</span>{{element.change}}
            </td>
          </ng-container>

          <ng-container matColumnDef="change-percent">
            <th mat-header-cell *matHeaderCellDef>Chg. %</th>
            <td mat-cell *matCellDef="let element" [class]="{positive: element.change > 0, negative: element.change < 0}">
              <span *ngIf="element.change > 0">+</span>{{element.changePercent}}
            </td>
          </ng-container>

          <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef>Time</th>
            <td mat-cell *matCellDef="let element">{{element.time}}</td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">{{element.status}}</td>
          </ng-container>

          <ng-container matColumnDef="volume">
            <th mat-header-cell *matHeaderCellDef>Volume</th>
            <td mat-cell *matCellDef="let element">{{element.volume}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns; market" (click)="goTo(row, market)"></tr>
        </table>
      </mat-tab>
    </mat-tab-group>
  </mat-tab>
</mat-tab-group>
