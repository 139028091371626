import { DataGenerator } from "./data-generator.model";

export class LoadDataGenerator {
  static readonly type = '[DataGenerator] Read';
  constructor(public id: string) { }
}

export class LoadMoreGenerators {
  static readonly type = '[DataGenerator] Load More Generators';
  constructor(public tag: string | null, public loadPredictions: boolean = true) {}
}

export class LoadTagDataGenerators {
  static readonly type = '[DataGenerator] Load Symbol';
  constructor(public tag: string, public loadPredictions: boolean = true) { }
}

export class LoadUserDataGenerators {
  static readonly type = '[DataGenerator] Load User';
  constructor() { }
}

export class CreateDataGenerator {
  static readonly type = '[DataGenerator] Create';
  constructor(public payload: DataGenerator) { }
}

export class UpdateDataGenerator {
  static readonly type = '[DataGenerator] Update';
  constructor(public generator: DataGenerator) { }
}

export class DeleteDataGenerator {
  static readonly type = '[DataGenerator] Delete';
  constructor(public id: string) { }
}

export class RetryDataGenerator {
  static readonly type = '[DataGenerator] Retry';
  constructor(public id: string) { }
}
