@if(user.isAuthenticated) {
<a class="display-name" matTooltip="Username" routerLink="/profile">
  <mat-icon *ngIf="isAdmin$ | async" class="admin-icon">shield_person</mat-icon>
  {{ user.isAnonymous ? "Anonymous" : user.displayName }}
</a>
<mat-icon (click)="logout()" class="logout" matTooltip="Logout">
  logout
</mat-icon>
} @else {
<a routerLink="/login" class="login">Login</a>
}
