<app-data-generator-breadcrumb></app-data-generator-breadcrumb>
<div class="generator-overview-container">
  <h1>News Summarizer</h1>

  <mat-chip-listbox class="filter-chips" [value]="selectedFilter" (change)="filterChange($event)">
    <mat-chip-option [value]="FilterType.ALL">All</mat-chip-option>
    <mat-chip-option [value]="FilterType.STOCKS">Stocks</mat-chip-option>
    <mat-chip-option [value]="FilterType.CRYPTOS">Cryptos</mat-chip-option>
    <mat-chip-option [value]="FilterType.TOPICS">Topics</mat-chip-option>
    <mat-chip-option [value]="FilterType.MINE" *ngIf="isAuthenticated$ | async">Mine</mat-chip-option>
  </mat-chip-listbox>

  <div class="table-controls">
    <mat-form-field class="search-field">
      <mat-label>Search</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Search generators" #input>
    </mat-form-field>
  </div>

  <mat-table [dataSource]="dataSource" class="generator-table" matSort>
    <ng-container matColumnDef="scope">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Scope </mat-header-cell>
      <mat-cell *matCellDef="let generator" [matTooltip]="generator.description"> {{getScope(generator)}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
      <mat-cell *matCellDef="let generator" [matTooltip]="generator.description"> {{generator.name}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
      <mat-cell *matCellDef="let generator">
        <app-data-generator-status [generator]="generator"></app-data-generator-status>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="runFrequency">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Run Frequency </mat-header-cell>
      <mat-cell *matCellDef="let generator"> {{generator.runFreq}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="startedAt">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Started At </mat-header-cell>
      <mat-cell *matCellDef="let generator" [matTooltip]="(generator?.startedAt | date:'medium') || ''">
        {{generator.startedAt | date:'dd/MM/yyyy'}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="updatedAt">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Updated At </mat-header-cell>
      <mat-cell *matCellDef="let generator" [matTooltip]="(generator?.updatedAt | date:'medium') || ''">
        {{generator.updatedAt | date:'dd/MM/yyyy'}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="owner">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Owner </mat-header-cell>
      <mat-cell *matCellDef="let generator" [matTooltip]="generator.ownerId">
        <span class="owner-cell">{{generator.ownerId}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cost">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Cost </mat-header-cell>
      <mat-cell *matCellDef="let generator"> {{formatCost(generator.cost)}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let generator">
        <button mat-icon-button [matMenuTriggerFor]="rowMenu" (click)="openMenu($event, generator)"
          aria-label="Actions">
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" matTooltipPosition="above" (click)="viewGenerator(row)"
      class="generator-row">
    </mat-row>
  </mat-table>

  <mat-menu #rowMenu="matMenu">
    <app-data-generator-menu
      [generator]="selected!"
      [isAdmin]="false">
    </app-data-generator-menu>
  </mat-menu>

  <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of generators">
  </mat-paginator>

  <div class="new-wrapper">
    <ng-container *ngIf="isAuthenticated$ | async; else loginButton">
      <button mat-raised-button color="primary"
              (click)="createGenerator()"
              class="add-button"
              title="Create new summarizer">
          New
      </button>
    </ng-container>
    <ng-template #loginButton>
      <p>Login to create and edit news summarizers</p>
      <button mat-raised-button
              (click)="login()">
          Login
      </button>
    </ng-template>
  </div>
</div>
