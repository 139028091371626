import { Component, Optional, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SymbolService } from '@ticker/services/symbol.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-market-overview',
  templateUrl: './market-overview.component.html',
  styleUrl: './market-overview.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class MarketOverviewComponent {
  markets: any = [];
  displayedColumns: string[] = ['name', 'last', 'high', 'low', 'change', 'change-percent', 'volume', 'time', 'status'];

  constructor(
    private symbolService: SymbolService,
    private router: Router,
    @Optional() private toastr?: ToastrService) {

    this.symbolService.loadOverview().then((data: any) => {
      if ( data.exists() ) {
        this.markets = data.data().data;
      } else {
        this.markets = [];
      }
    }).catch((error) => {
      console.error(error);
      this.toastr?.error(error, "Error");
    });
  }

  goTo(row: any, market: any) {
    if ( market.name == "Stocks" ) {
      this.router.navigate(['tickers', 'stock', row.symbol])
    } else if ( market.name == "Crypto" ) {
      this.router.navigate(['tickers', 'crypto', row.symbol])
    }
  }
}
