import { Injectable, Optional } from '@angular/core';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, map } from 'rxjs';

import { FullPrediction } from "../../core/stores/prediction/prediction.model";

@Injectable({
  providedIn: 'root'
})
export class PredictionService {
  predictionIdSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  predictionId$: Observable<any> = this.predictionIdSubject.asObservable();

  predictionDateSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  predictionDate$: Observable<any> = this.predictionDateSubject.asObservable();

  prediction$: Observable<FullPrediction | undefined> = this.predictionId$.pipe(
    map((predictionId) => this.predictions[predictionId])
  );
  predictions: { [key: string]: FullPrediction } = {};
  predictionPromises: { [key: string]: Promise<any> } = {};

  constructor(
    private firestore: Firestore,
    @Optional() private toastr?: ToastrService)
  { }

  setPrediction(predictionId: string | undefined, date?: Date | undefined) {
    // If the predictionId is already set, don't reload it
    if (predictionId === this.predictionIdSubject.value &&
        date === this.predictionDateSubject.value) return;

    // If the predictionId is undefined, clear the current prediction
    if (!predictionId || predictionId in this.predictions) {
      this.predictionDateSubject.next(date);
      this.predictionIdSubject.next(predictionId);
      return;
    }

    // Load the prediction and set the predictionId
    return this.loadPrediction(predictionId).then(() => {
      this.predictionDateSubject.next(date);
      this.predictionIdSubject.next(predictionId);
    }).catch((error) => {
      console.error('Error loading prediction:', error);
      this.toastr?.error(error, 'Error loading prediction');
    });
  }

  loadPrediction(predictionId: string): Promise<any> {
    if (!predictionId) return Promise.resolve();
    if (predictionId in this.predictions) {
      return Promise.resolve(this.predictions[predictionId]);
    }
    if (predictionId in this.predictionPromises) {
      return this.predictionPromises[predictionId];
    }

    return this.predictionPromises[predictionId] = getDoc(
      doc(this.firestore, 'predictions', predictionId)
    ).then((p) => {
      const prediction: any = {
        ...p.data(),
        prediction_id: p.id,
      };
      this.predictions[predictionId] = prediction as FullPrediction;
      return prediction;
    }).catch((error) => {
      console.error('Error loading prediction:', error);
      this.toastr?.error(error, 'Error loading prediction');
      return {};
    });
  }

  clear() {
    this.predictionIdSubject.next(undefined);
    this.predictionDateSubject.next(undefined);
  }
}
