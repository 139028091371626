import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GaAutocompleteComponent } from './core/components/ga-autocomplete/ga-autocomplete.component';
import { AdminGuard } from './core/guards/admin.guard';
import { HOME_ROUTES } from './home/home-routing.module';
import { GaHelpComponent } from './home/pages/ga-help/ga-help.component';
import { GaHomeComponent } from './home/pages/ga-home/ga-home.component';
import { LoginPageComponent } from './home/pages/login-page/login-page.component';

const routes: Routes = [
  ...HOME_ROUTES,

  { path: 'news', component: GaHomeComponent },
  { path: 'search', component: GaHomeComponent },
  { path: 'login', component: LoginPageComponent },
  { path: 'help', component: GaHelpComponent },

  // Lazy loaded modules
  {
    path: 'tickers',
    loadChildren: () => import('./ticker/ticker.module').then(m => m.TickerModule)
  },
  {
    path: 'ticker',
    loadChildren: () => import('./ticker/ticker.module').then(m => m.TickerModule)
  },
  {
    path: 'summarizers',
    loadChildren: () => import('./data-generator/data-generator.module').then(m => m.DataGeneratorModule)
  },
  {
    path: 'prompts',
    loadChildren: () => import('./prompt/prompt.module').then(m => m.PromptModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'tuning',
    loadChildren: () => import('./tuning/tuning.module').then(m => m.TuningModule),
    canActivate: [AdminGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },

  // WIP
  { path: 'autocomplete', component: GaAutocompleteComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
