<div class="home-container">
  <mat-chip-listbox
    class="generator-chips"
    [selectable]="true">
    <mat-chip-option
      *ngFor="let g of generators$ | async"
      [value]="g"
      (click)="onGeneratorSelect(g)"
      [selected]="selectedGenerator?.news_summariser_id === g.news_summariser_id">
      {{ g.name }}
    </mat-chip-option>
  </mat-chip-listbox>

  <div class="generator-details" *ngIf="selectedGenerator">
    <app-data-generator-detail
      [generator]="selectedGenerator"
      [showPromptDetails]="false">
    </app-data-generator-detail>

    <div class="navigation-links">
      <a mat-button color="primary" [routerLink]="['/summarizers/view', selectedGenerator.news_summariser_id]">
        <mat-icon>visibility</mat-icon>
        See details
      </a>
      <a mat-button color="primary" [routerLink]="['/summarizers/list']">
        <mat-icon>list</mat-icon>
        See all summarizers
      </a>
      <a mat-button color="primary" [routerLink]="['/summarizers/create']">
        <mat-icon>create</mat-icon>
        Create your own
      </a>
    </div>
  </div>
</div>
