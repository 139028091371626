import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, PercentPipe } from '@angular/common';
import { SafeNumberPipe, SafePercentPipe } from './pipes/safe-number.pipe';
import { AdminGuard } from './guards/admin.guard';
import { NgxsModule } from '@ngxs/store';

import { MaterialModule } from './material.module';
import { GaAutocompleteDirective } from './components/ga-autocomplete/ga-autocomplete.directive';
import { GaAutocompleteDialogComponent } from './components/ga-autocomplete/ga-autocomplete-dialog.component';
import { GaAutocompleteDropdownComponent } from './components/ga-autocomplete/ga-autocomplete-dropdown.component';
import { GaAutocompleteComponent } from './components/ga-autocomplete/ga-autocomplete.component';
import { ConfirmDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DataItemState } from './stores/data-item/data-item.state';
import { PredictionState } from './stores/prediction/prediction.state';
import { UserState } from './stores/user/user.state';
import { DataItemListComponent } from './components/data-item-list/data-item-list.component';

@NgModule({
  declarations: [
    GaAutocompleteDialogComponent,
    GaAutocompleteDropdownComponent,
    GaAutocompleteDirective,
    GaAutocompleteComponent,
    ConfirmDialogComponent,
    DataItemListComponent,

    SafeNumberPipe,
    SafePercentPipe
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgxsModule.forFeature([
      DataItemState,
      PredictionState,
      UserState
    ]),
  ],
  exports: [
    GaAutocompleteDirective,
    GaAutocompleteComponent,
    ConfirmDialogComponent,
    DataItemListComponent,
    SafeNumberPipe,
    SafePercentPipe
  ],
  providers: [
    AdminGuard,
    DecimalPipe,
    PercentPipe
  ]
})
export class GaCoreModule { }
