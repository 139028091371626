<header class="header">
  <div class="container">
    <div class="logo-container">
      <button mat-icon-button (click)="snav.toggle()" class="menu-icon">
        <mat-icon>menu</mat-icon>
      </button>
      <a class="logo" (click)="home()" title="Home">
        <img src="../../../assets/logo_white_no_text.svg" height="32" alt="Logo">
      </a>
      <a class="logo-text" (click)="home()">
        <div>GPT Analyst</div>
        <span class="slogan">Decoding Markets with AI</span>
      </a>
    </div>
    <div class="menu">
        <a href="https://newsletter.gptanalyst.app" target="_blank" class="menu-item">
          Blog
        </a>
        <a routerLink="product" class="menu-item">
          Product
        </a>
        <a routerLink="consulting" class="menu-item">
          Consulting
        </a>
        <div class="login">
          <app-login></app-login>
        </div>
    </div>
  </div>
</header>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #snav [mode]="'over'" fixedInViewport="true" fixedTopGap="0">
    <mat-icon class="close" (click)="snav.toggle()">close</mat-icon>
    <mat-nav-list>
      <a mat-list-item routerLink="." (click)="snav.toggle()">
        <mat-icon>home</mat-icon>
        Home
      </a>
      <a mat-list-item routerLink="news" (click)="snav.toggle()">
        <mat-icon>article</mat-icon>
        News
      </a>
      <a mat-list-item routerLink="summarizers" (click)="snav.toggle()">
        <mat-icon>summarize</mat-icon>
        Summarizers
      </a>
      <a mat-list-item href="https://newsletter.gptanalyst.app" target="_blank" (click)="snav.toggle()">
        <mat-icon>rss_feed</mat-icon>
        Blog
      </a>
      <a mat-list-item routerLink="help" (click)="snav.toggle()">
        <mat-icon>support</mat-icon>
        Documentation
      </a>

      <a mat-list-item routerLink="admin" (click)="snav.toggle()" *ngIf="isAdmin$ | async">
        <mat-icon>admin_panel_settings</mat-icon>
        Admin
      </a>
      <a mat-list-item routerLink="/admin/statistics" (click)="snav.toggle()" *ngIf="isAdmin$ | async">
        <mat-icon>analytics</mat-icon>
        Statistics
      </a>

      @if(isAuthenticated$ | async) {
        <a mat-list-item routerLink="profile" (click)="snav.toggle()">
          <mat-icon>person</mat-icon>
          Profile
        </a>
        <a mat-list-item routerLink="." (click)="logout(); snav.toggle()">
          <mat-icon>logout</mat-icon>
          Logout
        </a>
      }@else {
        <a mat-list-item routerLink="login" (click)="snav.toggle()">
          <mat-icon>login</mat-icon>
          Login
        </a>
      }

      <a mat-list-item routerLink="feedback" (click)="snav.toggle()">
        <mat-icon>feedback</mat-icon>
        Feedback
      </a>

      <br>
      <a mat-list-item routerLink="product" (click)="snav.toggle()">
        <mat-icon>settings</mat-icon>
        Product
      </a>

      <a mat-list-item routerLink="consulting" (click)="snav.toggle()">
        <mat-icon>manage_accounts</mat-icon>
        Consulting
      </a>

    </mat-nav-list>
  </mat-sidenav>
</mat-sidenav-container>
