import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataGenerator } from '../../stores/data-generator/data-generator.model';
import { DataGeneratorSelectors } from '../../stores/data-generator/data-generator.selector';
import { MatSort } from '@angular/material/sort';
import { LoadMoreGenerators } from '../../stores/data-generator/data-generator.action';
import { UserState } from '@core/stores/user/user.state';
import { MatChipListboxChange } from '@angular/material/chips';

const BASE_COLUMNS = ['scope', 'name', 'status', 'runFrequency', 'startedAt', 'updatedAt', 'actions'];
const ADMIN_COLUMNS = [...BASE_COLUMNS.slice(0, 6), 'owner', 'cost', 'actions'];

export enum FilterType {
  ALL = 'all',
  STOCKS = 'stocks',
  CRYPTOS = 'cryptos',
  TOPICS = 'topics',
  MINE = 'mine'
}

@Component({
  selector: 'app-data-generator-overview',
  templateUrl: './data-generator-overview.component.html',
  styleUrls: ['./data-generator-overview.component.scss']
})
export class DataGeneratorOverviewComponent implements OnInit {
  displayedColumns: string[] = BASE_COLUMNS;
  dataSource = new MatTableDataSource<DataGenerator>([]);
  private originalData: DataGenerator[] = [];
  selected: DataGenerator | null = null;
  selectedFilter: FilterType = FilterType.ALL;
  FilterType = FilterType;
  isAuthenticated$: Observable<boolean> =  this.store.select(UserState.isAuthenticated);

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  private pageSize = 10;
  private currentSymbol: string | null = null;
  private currentTopic: string | null = null;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.store.select(UserState.isAdmin).subscribe(isAdmin => {
      if (isAdmin) {
        this.displayedColumns = ADMIN_COLUMNS;
      }
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.currentSymbol = params['symbol']?.toLowerCase();
      this.currentTopic = params['name']?.toLowerCase();
      this.dataSource = new MatTableDataSource<DataGenerator>();
      this.loadGenerators();
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.paginator.page.subscribe(() => {
      if (this.isLastPage()) {
        this.loadMoreGenerators();
      }
    });

    this.loadMoreGenerators();
  }

  private loadGenerators() {
    this.store.select(DataGeneratorSelectors.selectAll).pipe(
      map(generators => this.filterGeneratorsByScope(generators))
    ).subscribe(generators => {
      this.originalData = [...generators];
      this.dataSource.data = this.originalData;
      this.dataSource.paginator = this.paginator;
    });
  }

  private filterGeneratorsByScope(generators: DataGenerator[]): DataGenerator[] {
    if (!this.currentSymbol) return generators;

    return generators.filter(generator =>
      generator.tags?.some(tag => {
        if (this.currentTopic) {
          return tag.toLowerCase() === `topic:${this.currentTopic}`;
        } else if (this.currentSymbol) {
          return tag.toLowerCase() === `symbol:${this.currentSymbol}`;
        }
        return false;
      })
    );
  }

  private isLastPage(): boolean {
    const maxPages = Math.ceil(this.dataSource.data.length / this.pageSize);
    return this.paginator.pageIndex === maxPages - 1;
  }

  private loadMoreGenerators() {
    this.store.dispatch(new LoadMoreGenerators(this.currentSymbol, false));
  }

  viewGenerator(g: DataGenerator) {
    this.router.navigate(['../view', g.news_summariser_id], { relativeTo: this.route });
  }

  createGenerator() {
    this.router.navigate(['../create'], { relativeTo: this.route });
  }

  getScope(generator: DataGenerator): string {
    const scopeTag = generator.tags?.find(tag =>
      tag.startsWith('topic:') || tag.startsWith('symbol:')
    );
    return scopeTag ? scopeTag.split(':')[1] : 'N/A';
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.applyFilters();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filterChange(event: MatChipListboxChange) {
    this.selectedFilter = event.value;
    this.applyFilters();
  }

  private applyFilters() {
    let filteredData = [...this.originalData];

    const filterFunctions = {
      [FilterType.STOCKS]: (g: DataGenerator) => g.tags?.some(tag => tag.startsWith('symbol:')),
      [FilterType.CRYPTOS]: (g: DataGenerator) => g.tags?.some(tag => tag.startsWith('crypto:')),
      [FilterType.TOPICS]: (g: DataGenerator) => g.tags?.some(tag => tag.startsWith('topic:')),
      [FilterType.MINE]: (g: DataGenerator) => g.isOwner
    };

    if (this.selectedFilter !== FilterType.ALL) {
      const filterFn = filterFunctions[this.selectedFilter];
      if (filterFn) {
        filteredData = filteredData.filter(filterFn);
      }
    }

    if (this.dataSource.filter) {
      this.dataSource.filter = this.dataSource.filter.toLowerCase();
    }

    this.dataSource.data = filteredData;
  }

  openMenu(event: Event, generator: DataGenerator) {
    event.stopPropagation();
    this.selected = generator;
  }

  formatCost(cost: number | undefined): string {
    if (!cost) return '$0.00';
    return `$${cost.toFixed(3)}`;
  }

  login() {
    this.router.navigate(['/login']);
  }
}
