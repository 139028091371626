import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { Store } from '@ngxs/store';
import { Subject, takeUntil } from 'rxjs';
import { TimelineEntry } from '../../stores/news-timeline/news-timeline.model';
import { LoadTimeline, NewsTimelineState } from '../../stores/news-timeline/news-timeline.state';

export const getSentimentColor = function (sentiment: string): string {
  switch (sentiment) {
    case 'Very Negative': return '#ff4c4c'; // Red
    case 'Negative': return '#ffa500'; // Orange
    case 'Neutral': return '#cccccc'; // Gray
    case 'Positive': return '#8bc34a'; // Green
    case 'Very Positive': return '#4caf50'; // Dark Green
    default: return '#cccccc'; // Default Gray
  }
}

@Component({
  selector: 'app-data-generator-timeline',
  templateUrl: './data-generator-timeline.component.html',
  styleUrls: ['./data-generator-timeline.component.scss']
})
export class DataGeneratorTimelineComponent implements OnChanges, OnDestroy {
  private destroy$ = new Subject<void>();

  @Input() summarizerId: string | null = null;
  @Output() dateClicked = new EventEmitter<Date>();

  @Input()
  set selectedDate(date: Date | undefined) {
    if (!date) return;

    this.selectedSeconds = date.getTime() / 1000;
  }

  timeline: any[] = [];
  dateMarkers: { left: number; label: string }[] = [];
  selectedSeconds: number = -1;

  constructor(private store: Store) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes as any).summarizerId && this.summarizerId) {
      this.loadTimeline(this.summarizerId);
    }
  }

  getTooltip(bar: any) {
    const date = new Date(bar.date.seconds * 1000);
    return `${date.toDateString()}
    Sentiment: ${bar.sentiment}
    Distance: ${bar.distance * 100}% (to previous)`;
  }

  private loadTimeline(summarizer_id: string): void {
    this.store.dispatch(new LoadTimeline(summarizer_id));
    this.store
      .select(NewsTimelineState.getTimelineById(summarizer_id))
      .pipe(takeUntil(this.destroy$)).subscribe((timeline) => {
        if (!timeline?.timeline) return;
        this.timeline = this.processTimeline(timeline.timeline);
      });
  }

  private processTimeline(entries: TimelineEntry[]): any[] {
    entries = entries.filter((dimension) => dimension.distance?.length > 0); // Remove dimensions with no data
    entries = entries.sort((a, b) => a.name.localeCompare(b.name)); // Sort dimensions alphabetically

    // Determine the earliest and latest date across all timelines
    const uniqueDates = new Set<number>();
    let earliestDate = Infinity;
    let latestDate = -Infinity;

    entries.forEach(dimension => {
      dimension.distance.forEach(({ date }: any) => {
        uniqueDates.add(date.seconds); // Add the date to the Set
        if (date < earliestDate) earliestDate = date;
        if (date > latestDate) latestDate = date;
      });
    });

    if (uniqueDates.size < 5) return []; // If there are less than 5 unique dates, return empty

    // Calculate date markers
    const dateRange = latestDate - earliestDate;
    this.setDateMarkers(uniqueDates);

    const totalUniqueDates = uniqueDates.size;
    const width = 100 / totalUniqueDates;

    return entries.map((dimension) => {
      return {
        name: dimension.name,
        bars: dimension.distance.map((d: any) => {
          const sentiment = this.getSentimentForDate(dimension.sentiment, d.date);
          const left = ((d.date - earliestDate) / dateRange) * (100 - width); // Calculate left in %

          return {
            width: width, // Bar width proportional to number of dimensions
            height: Math.max(d.distance * 24, 4), // Bar height proportional to distance
            left: left,
            color: getSentimentColor(sentiment),
            date: d.date,
            sentiment: sentiment,
            distance: d.distance.toFixed(2)
          };
        })
      };
    });
  }

  private setDateMarkers(uniqueDates: Set<number>): void {
    // Step 1: Convert the set to a sorted array
    const sortedDates = Array.from(uniqueDates).sort((a, b) => a - b);

    // Step 2: Calculate step size for 5 equally spaced dates
    const step = Math.floor(sortedDates.length / 5);

    // Step 3: Pick 5 equally spaced dates
    const selectedDates = [];
    for (let i = 0; i < 5; i++) {
      const index = i * step; // Calculate the index
      const seconds = sortedDates[Math.min(index, sortedDates.length - 1)]; // Get the date
      const date = new Date(seconds * 1000); // Convert to Date object
      selectedDates.push({ left: 20 * i, label: date.toDateString() });
    }

    this.dateMarkers = selectedDates;
  }

  private getSentimentForDate(sentiments: any[], date: Timestamp): string {
    let lastSentiment = 'Neutral';
    for (const sentiment of sentiments) {
      if (sentiment.date <= date) {
        lastSentiment = sentiment.sentiment;
      }
    }
    return lastSentiment;
  }

  onBarClick(date: Timestamp): void {
    this.selectedSeconds = date.seconds;
    this.dateClicked.emit(date.toDate());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
