
import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { TimelineEntry, NewsTimelineData } from './news-timeline.model';
import { doc, onSnapshot } from 'firebase/firestore';
import { Firestore } from '@angular/fire/firestore';

export interface NewsTimelineStateModel {
  timelines: { [id: string]: NewsTimelineData };
}

export class LoadTimeline {
  static readonly type = '[NewsTimeline] Load';
  constructor(public summarizer_id: string) {}
}

@State<NewsTimelineStateModel>({
  name: 'newsTimelineState',
  defaults: {
    timelines: {},
  },
})
@Injectable()
export class NewsTimelineState {
  private timelineUnsubscribe: any;

  constructor(private db: Firestore) {}

  static getTimelineById(summarizer_id: string) {
    return createSelector([NewsTimelineState], (state: NewsTimelineStateModel) => {
      return state.timelines[summarizer_id] || null;
    });
  }

  static getNameMappingsById(summarizer_id: string) {
    return createSelector([NewsTimelineState], (state: NewsTimelineStateModel) => {
      const timeline = state.timelines[summarizer_id];
      if (!timeline) return {};

      const nameMappings: { [originalName: string]: string } = {};
      timeline.timeline.forEach((entry: TimelineEntry) => {
        entry.names.forEach((originalName) => {
          nameMappings[originalName] = entry.name;
        });
      });
      return nameMappings;
    });
  }

  @Action(LoadTimeline)
  loadTimeline(ctx: StateContext<NewsTimelineStateModel>, action: LoadTimeline): void {
    const state = ctx.getState();
    const docRef = doc(this.db, 'newstimeline', action.summarizer_id);

    this.timelineUnsubscribe?.();
    this.timelineUnsubscribe = onSnapshot(
      docRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data() as NewsTimelineData;
          ctx.patchState({
            timelines: {
              ...state.timelines,
              [action.summarizer_id]: data,
            },
          });
        }
      },
      (error) => {
        console.error('Error fetching timeline:', error);
      }
    );
  }
}
