import { Injectable, Optional } from '@angular/core';
import { DocumentSnapshot, Firestore, Query, Unsubscribe, collection, doc, getDoc, limit, onSnapshot, query, where } from '@angular/fire/firestore';
import { FirebaseHttpService } from '@core/services/firebase-http.service';
import { CryptoSymbol, GaSymbol, createSymbol } from '@ticker/stores/symbol/symbol.model';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, map } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class SymbolService {
  private detailUnsubscribe: Unsubscribe | undefined;
  private performanceUnsubscribe: Unsubscribe | undefined;
  private isLoading: any = {};

  symbolSubject: BehaviorSubject<GaSymbol> = new BehaviorSubject<any>(undefined);
  symbol$: Observable<GaSymbol> = this.symbolSubject.asObservable();

  symbolDetailsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  symbolDetails$: Observable<any> = this.symbolDetailsSubject.asObservable();

  symbolPerformanceSubject: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  symbolPerformance$: Observable<any> = this.symbolPerformanceSubject.asObservable();

  lastPriceDetails$: Observable<any> = this.symbolPerformance$.pipe(
    map((data: any) => {
      const entries = Object.entries(data);
      const lastEntry: any = entries.pop();
      if (!lastEntry) return;

      const secondLastEntry: any = entries.pop();
      if (!secondLastEntry) return;
      const symbol = this.symbolSubject.value;
      const lastPrice = lastEntry[1][symbol.priceField];
      const secondLastPrice = secondLastEntry[1][symbol.priceField];
      return {
        lastPrice: lastPrice,
        priceChange: lastPrice - secondLastPrice,
        priceChangePercent: ((lastPrice - secondLastPrice) / secondLastPrice) * 100,
      };
    })
  );

  constructor(
    private fbHttps: FirebaseHttpService,
    private db: Firestore,
    @Optional() private toastr?: ToastrService)
  { }

  select(assetClass: string | undefined, ticker: string | undefined) {
    if (this.symbolSubject.value?.ticker === ticker) return;

    const symbol = createSymbol(assetClass ?? 'stock', ticker ?? '', this.db);
    this.symbolSubject.next(symbol);
    if (!ticker) {
      this.symbolDetailsSubject.next(undefined);
      this.symbolPerformanceSubject.next([]);
      return;
    }

    this.loadDetails(symbol);
    this.loadPerformance(symbol);
  }

  loadDetails(symbol: GaSymbol) {
    // Only meaningful for stocks
    if (symbol instanceof CryptoSymbol) return;

    const overview = query(
      collection(this.db, 'data'),
      where("symbol", "==", symbol.ticker),
      where("function", "==", "OVERVIEW"),
      limit(1)
    );
    let reload: boolean = true;
    this.detailUnsubscribe?.();
    this.detailUnsubscribe = onSnapshot(overview, (snapshot) => {
      snapshot.forEach((odoc) => {
        const now = new Date().getTime() / 1000;
        reload = odoc.data()['stale_at'] !== null && odoc.data()['stale_at']['seconds'] < now;

        const doc_data = odoc.data()['data'];
        this.setDetails(doc_data);
      });

      const key = 'get_ticker_overview' + symbol.ticker;
      if (reload && !this.isLoading[key]) {
        console.log("Data doesn't exist or is out of date, reloading it.");
        this.isLoading[key] = true;
        this.fbHttps
          .call('api', { method: 'get_ticker_overview', symbol: symbol.fullTicker })
          .catch((error) => {
            this.toastr?.error(error, 'Error loading details');
          })
          .finally(() => {
            this.isLoading[key] = false;
          });
      }
    });
  }

  setDetails(data: any) {
    if (data.Symbol !== this.symbolSubject.value.ticker) return;

    this.symbolDetailsSubject.next({
      Name: 'Not Found',
      Symbol: '???',
      Description: 'Missing',
      Currency: '???',
      ...data,
    });
  }

  loadPerformance(symbol: GaSymbol) {
    const now = new Date();
    const nowTs = now.getTime() / 1000;
    const adjusted: Query = symbol.getAdjustedPriceQuery();
    let reload: boolean = true;
    this.performanceUnsubscribe?.();
    this.performanceUnsubscribe = onSnapshot(adjusted, (snapshot) => {
      snapshot.forEach((pdoc) => {
        reload = (pdoc.data()['stale_at'] === null || pdoc.data()['stale_at']['seconds'] > nowTs) ? false : true;
        getDoc(doc(this.db, pdoc.ref.path, 'data', symbol.priceField2))
          .then((sdoc) => {
            if (sdoc.exists()) {
              const data: any = sdoc.data()['data'];
              data.sort((a: any, b: any) => a.valid_at["seconds"] - b.valid_at["seconds"]);
              this.setPerformance(symbol, data);
            } else {
              reload = true;
              console.log("Failed to load data");
            }
          })
          .catch((error) => {
            this.toastr?.error(error, 'Error loading series');
          });
      });

      if (reload) {
        this.updateDailySeries(symbol);
      }
    });
  }

  async loadOverview(): Promise<DocumentSnapshot> {
    return getDoc(doc(this.db, 'data', 'market'));
  }

  private updateDailySeries(symbol: GaSymbol){
    const key = 'get_ticker_daily_series' + symbol.fullTicker;
    if (!this.isLoading[key]) {
      console.log("Data doesn't exist or is out of date, reloading it.");
      this.isLoading[key] = true;
      this.fbHttps
        .call('api', {
          method: 'get_ticker_daily_series',
          symbol: symbol.fullTicker,
          series: [symbol.priceField],
        })
        .then(
          () => {
            this.loadPerformance(symbol);
          }
        )
        .catch((error) => {
          this.toastr?.error(error, 'Error loading series');
        })
        .finally(() => {
          this.isLoading[key] = false;
        });
    }
  }

  private setPerformance(symbol: GaSymbol, data: any) {
    if (symbol.ticker !== this.symbolSubject.value?.ticker) return;
    this.symbolPerformanceSubject.next(data);
  }
}
