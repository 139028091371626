<div class="timeline-container" *ngIf="timeline.length > 0">

  <div class="date-marker-row">
    <div
      *ngFor="let date of dateMarkers"
      class="date-marker"
      [style.left.%]="date.left">
      {{ date.label }}
    </div>
  </div>

  <div *ngFor="let dimension of timeline" class="dimension-row">
    <div class="dimension-name" matTooltip="{{ dimension.name }}">{{ dimension.name }}</div>
    <div class="dimension-bars">
      <div
        *ngFor="let bar of dimension.bars"
        class="bar-wrapper"
        [class.selected]="bar.date.seconds == selectedSeconds"
        [style.left.%]="bar.left"
        [style.width.%]="bar.width"
        (click)="onBarClick(bar.date)"
        [matTooltip]="getTooltip(bar)"
        matTooltipClass="bar-tooltip">
          <div
            class="bar"
            [style.height.px]="bar.height"
            [style.background-color]="bar.color">
          </div>
      </div>
    </div>
  </div>

</div>
