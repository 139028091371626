import { Injectable } from '@angular/core';
import { Auth, User, onAuthStateChanged } from '@angular/fire/auth';
import { Firestore, Timestamp, addDoc, collection, getDocs, query, where } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ConsentDialogComponent } from '../dialogs/consent-dialog/consent-dialog.component';

export interface Consent {
  userId?: string;
  agreed: boolean;
  ageVerified: boolean;
  timestamp?: Timestamp;
  ipAddress?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConsentService {
  private checkConsentSubject = new Subject<string | null>();
  private userSubject = new BehaviorSubject<User | null>(null);

  private hasConsent: boolean | undefined = undefined;
  private user: User | null = null;

  constructor(
    private db: Firestore,
    private dialog: MatDialog,
    private cookieService: CookieService,
    router: Router,
    auth: Auth) {
    onAuthStateChanged(
      auth,
      (user) => {
        this.hasConsent = undefined;
        this.userSubject.next(user);
        this.user = user;
      },
      (error) => {
        console.error('Error listening to auth state changes:', error);
      }
    );

    combineLatest([router.events, this.userSubject])
      .subscribe(([val, user]: any) => {
        const url = val.url;
        if (url) {
          const needsConsent =
            url.startsWith('/ticker') ||
            url.startsWith('/search') ||
            url === '/';

          if (needsConsent) {
            this.checkConsentSubject.next(user?.uid ?? null);
          }
        }
      });

    this.checkConsentSubject.pipe(
      debounceTime(500)
    ).subscribe(userId => {
      if (this.hasConsent === undefined) {
        this.checkAndOpenConsentDialog(userId ?? undefined);
      }
    });
  }

  async setConsent(consent: Consent) {
    consent.timestamp = Timestamp.now();
    consent.ipAddress = await this.getIpAddress();
    consent.userId = this.user?.uid ?? 'anonymous';

    const consentsRef = collection(this.db, 'consents');
    await addDoc(consentsRef, consent);

    this.cookieService.set('userConsent', 'true', 365, '/');
    this.hasConsent = true;
  }

  async checkAndOpenConsentDialog(userId?: string) {
    // Check for the cookie first
    const hasConsentCookie = this.cookieService.check('userConsent');
    if (hasConsentCookie) {
      console.log('Consent found in cookie');
      this.hasConsent = true;
      return;
    } else if (!userId) {
      this.askForConsent();
      return;
    }

    const consentsRef = collection(this.db, 'consents');
    const q = query(consentsRef, where('userId', '==', userId));
    getDocs(q).then(async (snapshot) => {
      if (snapshot.empty) {
        console.log('No consent found: ' + userId);
        this.askForConsent();
      } else {
        console.log('Consent found: ' + userId);
        this.hasConsent = true;
      }
    }).catch((error) => {
      console.error('Error loading consents', error);
      this.askForConsent();
    });
  }

  private askForConsent() {
    this.dialog.open(ConsentDialogComponent, {
      width: '480px',
      disableClose: true
    });
  }

  private async getIpAddress(): Promise<string> {
    const response = await fetch('https://ipv4.icanhazip.com');
    const data = await response.text();
    return data;
  }
}
