<div class="section-header" (click)="isExpanded = !isExpanded">
  <mat-icon>{{ isExpanded ? 'expand_more' : 'chevron_right' }}</mat-icon>
  <h2>Prompt Details</h2>
</div>

<div class="section-content" *ngIf="isExpanded">
  <div class="details-container">
    <div class="input-section">
      <h4>Input</h4>
      <markdown [data]="prediction?.input_text || 'No input available'"></markdown>
    </div>

    <div class="output-section">
      <h4>Summary</h4>
      <markdown [data]="prediction?.summary || 'No prediction available'"></markdown>
    </div>
  </div>
</div>
