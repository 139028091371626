import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataGenerator } from '../../stores/data-generator/data-generator.model';
import { DataGeneratorSelectors } from '../../stores/data-generator/data-generator.selector';
import { LoadDataGenerator } from '../../stores/data-generator/data-generator.action';

@Component({
  selector: 'app-data-generator-view',
  templateUrl: './data-generator-view.component.html',
  styleUrls: ['./data-generator-view.component.scss']
})
export class DataGeneratorViewComponent implements OnInit {
  generator$: Observable<DataGenerator | undefined>;
  isOwner$: Observable<boolean>;
  summarizerId: string | null = null;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.summarizerId = this.route.snapshot.paramMap.get('summarizerId');
    this.generator$ = this.store.select(DataGeneratorSelectors.selectById(this.summarizerId || ''));

    // Check if current user is the owner
    this.isOwner$ = this.generator$.pipe(
      map(generator => {
        return generator?.isOwner || false;
      })
    );
  }

  ngOnInit(): void {
    if (this.summarizerId) {
      this.store.dispatch(new LoadDataGenerator(this.summarizerId));
    }
  }

  onEdit(): void {
    this.router.navigate(['/summarizers/edit', this.summarizerId]);
  }
}
