<div class="title-container">

  <h3 class="summarizer-name" matTooltip="Generator name">{{ generator.name }}</h3>
  <app-data-generator-cost [generator]="generator" [isAdmin]="isAdmin$ | async"></app-data-generator-cost>

  <div class="options-wrapper">
    <app-data-generator-status [generator]="generator" [isLoading]="isLoading"></app-data-generator-status>

    <button mat-icon-button [matMenuTriggerFor]="menu" class="options-button">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <app-data-generator-menu
        [generator]="generator"
        [isAdmin]="isAdmin$ | async"
        (delete)="deleteGenerator()"
        (clone)="cloneGenerator()"
        (retry)="retryGenerator()">
      </app-data-generator-menu>
    </mat-menu>
  </div>

</div>

<app-data-generator-timeline
  [summarizerId]="generator.news_summariser_id"
  [selectedDate]="currentPrediction?.valid_at"
  (dateClicked)="select($event)">
</app-data-generator-timeline>

<div class="navigation" *ngIf="currentPrediction">

  <div class="news-date">
    {{ currentPrediction.valid_at | date:'medium' }} ({{ currentPrediction.valid_at | date:'z' }})

    <span class="prompt-cost" [matTooltip]="costToolTip">
      {{ currentPrediction ? cost : '' }}
    </span>
  </div>

  <div class="chevron-wrapper">
    <button mat-icon-button (click)="previous()" [disabled]="isFirst" class="chevron left">
      <mat-icon>chevron_left</mat-icon>
    </button>

    <button mat-icon-button (click)="next()" [disabled]="isLast" class="chevron right">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>

</div>

<app-data-generator-items
  [summarizerId]="generator.news_summariser_id"
  [prediction]="currentPrediction">
</app-data-generator-items>

<br>
<app-data-generator-prompt-details
  [prediction]="currentPrediction"
  *ngIf="showPromptDetails">
</app-data-generator-prompt-details>

<div class="navigation" *ngIf="currentPrediction">

  <div class="chevron-wrapper">
    <button mat-icon-button (click)="previous()" [disabled]="isFirst" class="chevron left">
      <mat-icon>chevron_left</mat-icon>
    </button>

    <button mat-icon-button (click)="next()" [disabled]="isLast" class="chevron right">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>

</div>
