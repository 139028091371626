// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { getApp, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCi_gLQZQZ8kxagp7whQM37l26Db3ID1Ac",
  authDomain: "gpt-analyst-lfi.firebaseapp.com",
  databaseURL: "https://gpt-analyst-lfi-default-rtdb.firebaseio.com",
  projectId: "gpt-analyst-lfi",
  storageBucket: "gpt-analyst-lfi.appspot.com",
  messagingSenderId: "729885540399",
  appId: "1:729885540399:web:17a5109499b94b91fe86da",
  measurementId: "G-WTX8RDBWBS"
};

export const isProduction = true;

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(getApp());
export const db = getFirestore();
export const auth = getAuth();
export const functions = getFunctions(firebaseApp);
export const database = getDatabase(firebaseApp)
