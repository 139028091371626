// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { AfterViewInit, Component } from '@angular/core';

const BoardToken = '6ed4fb90-4d43-ffb0-8ee7-f36fcbf72dfe';

@Component({
  selector: 'app-feedback-canny',
  template: '<div data-canny></div>'
})
export class FeedbackCannyComponent implements AfterViewInit {
  ngAfterViewInit() {
    // eslint-disable-next-line
    (function(w,d,i,s){function l(){if(!d.getElementById(i)){const f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){const c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}})(window,document,"canny-jssdk","script");

    Canny('render', {
      boardToken: BoardToken,
      basePath: null, // See step 2
      ssoToken: null, // See step 3
      theme: 'light', // options: light [default], dark, auto
    });
  }
}
