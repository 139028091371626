import { Injectable, OnDestroy } from '@angular/core';
import {
  Firestore,
  addDoc,
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
  updateDoc,
  doc
} from '@angular/fire/firestore';
import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';

import { FirebaseHttpService } from '@core/services/firebase-http.service';
import { LoadPredictionsForGenerator } from '../../../core/stores/prediction/prediction.state';
import { UserState } from '../../../core/stores/user/user.state';
import { DataGenerator } from './data-generator.model';
import {
  CreateDataGenerator,
  DeleteDataGenerator,
  LoadDataGenerator,
  LoadMoreGenerators,
  LoadTagDataGenerators,
  LoadUserDataGenerators,
  RetryDataGenerator,
  UpdateDataGenerator
} from './data-generator.action';

export interface DataGeneratorStateModel {
  dataGenerators: DataGenerator[];
  isCreating: boolean;
  lastGenerator: any;
}

@State<DataGeneratorStateModel>({
  name: 'dataGenerators',
  defaults: {
    dataGenerators: [],
    isCreating: false,
    lastGenerator: null
  },
})
@Injectable()
export class DataGeneratorState implements OnDestroy, NgxsOnInit {
  private symbolUnsubscribe: any;
  private userUnsubscribe: any;

  constructor(
    private store: Store,
    private db: Firestore,
    private firebaseHttp: FirebaseHttpService,
    private toastr: ToastrService)
  {}

  ngOnDestroy(): void {
    this.symbolUnsubscribe?.();
    this.userUnsubscribe?.();
  }

  ngxsOnInit(ctx: StateContext<DataGeneratorStateModel>): void {
    // Subscribe to user ID changes and load data generators when valid
    this.store.select(UserState.getUserId).subscribe(userId => {
      if (userId) {
        this.loadUserDataGenerators(ctx);
      }
    });
  }

  @Action(LoadTagDataGenerators)
  async loadSymbolDataGenerators(
    ctx: StateContext<DataGeneratorStateModel>,
    { tag, loadPredictions }: LoadTagDataGenerators) {

    const constraints = [where('tags', 'array-contains', tag)];
    this.symbolUnsubscribe?.();
    const [q, s] = this.loadDataGenerators(ctx, constraints, loadPredictions);
    this.symbolUnsubscribe = s;

    return q;
  }

  @Action(LoadUserDataGenerators)
  async loadUserDataGenerators(ctx: StateContext<DataGeneratorStateModel>) {
    const userId = this.getUserId();
    const constraints = [where('ownerId', '==', userId)];

    this.userUnsubscribe?.();
    const [q, s] = this.loadDataGenerators(ctx, constraints);
    this.userUnsubscribe = s;

    return q;
  }

  @Action(LoadMoreGenerators)
  loadMoreGenerators(
    ctx: StateContext<DataGeneratorStateModel>,
    { tag, loadPredictions }: LoadTagDataGenerators) {

      const lastGenerator = ctx.getState().lastGenerator;
      const constraints = [];

      if (tag) {
        constraints.push(where('tags', 'array-contains', tag));
      }

      constraints.push(orderBy('name', 'asc'));

      if (lastGenerator) {
        constraints.push(startAfter(lastGenerator));
      }

      constraints.push(limit(20));

      this.symbolUnsubscribe?.();
      const [q, s] = this.loadDataGenerators(ctx, constraints, loadPredictions);
      this.symbolUnsubscribe = s;

      return q;
  }

  loadDataGenerators(
    ctx: StateContext<DataGeneratorStateModel>,
    constraints: any[],
    loadPredictions: boolean = true) {

    const collectionRef = collection(this.db, 'data_generators');
    const q = query(collectionRef, ...constraints);

    let subscription = null;
    const promise = new Promise((resolve, reject) => {
      subscription = onSnapshot(
        q,
        (snapshot) => {
          const lastGenerator = snapshot.docs[snapshot.docs.length - 1];
          let generators: DataGenerator[] = this.snapshotToGenerators(snapshot);
          generators = this.mergeItems(ctx.getState, generators);

          // Update the state
          ctx.patchState({
            dataGenerators: generators,
            lastGenerator: lastGenerator
          });

          // Load predictions for each generator
          if (loadPredictions) {
            const ids = generators.map((g) => g.news_summariser_id).filter((id) => !!id);
            ctx.dispatch(new LoadPredictionsForGenerator(ids));
          }

          resolve(generators);
        },
        (error) => {
          if (this.toastr) this.toastr.error(error.message, 'Error syncing generators');
          reject(error);
        }
      );
    });

    return [promise, subscription];
  }

  private getUserId() {
    return this.store.selectSnapshot(UserState.getUserId) || '';
  }

  private snapshotToGenerators(snapshot: any): DataGenerator[] {
    const generators: any[] = [];
    snapshot.forEach((doc: any) => {
      const generator = this.documentToGenerator(doc.data());
      generators.push(generator);
    });
    return generators;
  }

  private documentToGenerator(data: any): DataGenerator {
    const userId = this.getUserId();
    return {
      ...data,
      id: data.id,
      isOwner: data.ownerId === userId,
      prompt: {
        ...data.prompt,
        template_body: data.prompt?.templateBody,
      }
    } as DataGenerator;
  }

  mergeItems(getState: () => DataGeneratorStateModel, items: DataGenerator[]): DataGenerator[] {
    // Get existing prompts that are not in the new list
    const existingItems = getState().dataGenerators.filter(
      (p1) => !items.some((p2) => p2.news_summariser_id === p1.news_summariser_id)
    );

    // Merge
    const allItems = [...existingItems, ...items];

    // Return
    return allItems;
  }

  @Action(CreateDataGenerator)
  async createDataGenerator(
    { getState, patchState }: StateContext<DataGeneratorStateModel>,
    { payload }: CreateDataGenerator) {

    try {
      patchState({ isCreating: true });

      // Create generator
      const response = await this.firebaseHttp.call('api', {
        method: 'news_aggregator_manager',
        action: 'create',
        name: payload.name,
        description: payload.description,
        template_body: payload.prompt.template_body,
        run_freq: payload.runFreq,
        started_at: payload.startedAt,
        timezone: payload.timezone,
        symbol: payload.symbol
      });

      // Add to state, use mergeItems to avoid duplicates
      const generator = this.documentToGenerator(response.data);
      patchState({
        dataGenerators: this.mergeItems(getState, [generator]),
      });

      this.toastr.success('Data Generator created successfully', 'Success');
    } catch (error: any) {
      console.log('error', error);
      this.toastr.error(`Error creating data generator: ${error.message}`, 'Error');
    } finally {
      patchState({ isCreating: false });
    }
  }

  @Action(LoadDataGenerator)
  async readDataGenerator(
    { getState, patchState }: StateContext<DataGeneratorStateModel>,
    { id }: LoadDataGenerator) {

    try {
      const response = await this.firebaseHttp.call('api', {
        method: 'news_aggregator_manager',
        action: 'read', id
      });

      const generator = this.documentToGenerator(response.data);
      const generators = getState().dataGenerators.filter((g) => g.news_summariser_id !== id);
      patchState({ dataGenerators: [...generators, generator] });
    } catch (error: any) {
      console.log('error', error);
      this.toastr.error(error.message, 'Error');
    }
  }

  @Action(DeleteDataGenerator)
  async deleteDataGenerator(
    { getState, patchState }: StateContext<DataGeneratorStateModel>,
    { id }: DeleteDataGenerator) {

    try {
      // Delete generator
      await this.firebaseHttp.call('api', {
        method: 'news_aggregator_manager',
        action: 'delete',
        id
      });

      // Remove from state
      patchState({
        dataGenerators: getState().dataGenerators.filter((g) => g.news_summariser_id !== id),
      });

      this.toastr.success('Data Generator deleted successfully', 'Success');
    } catch (error: any) {
      console.log('error', error);
      this.toastr.error('Error deleting data generator', 'Error');
    }
  }

  @Action(RetryDataGenerator)
  async retryDataGenerator(
    {}: StateContext<DataGeneratorStateModel>,
    { id }: RetryDataGenerator) {

    const collectionRef = collection(this.db, 'async_task_queue');
    const taskDocRef = query(
      collectionRef,
      where('ns_id', '==', id),
      where('task_type', '==', 'backfill_news')
    );

    try {
      const snapshot = await getDocs(taskDocRef);
      if (snapshot.empty) {
        await addDoc(
          collection(this.db, 'async_task_queue'),
          {
            ns_id: id,
            task_type: 'backfill_news',
          }
        );
        this.toastr.success('Retry task created successfully', 'Success');
      } else {
        this.toastr.info('Retry task already exists', 'Info');
      }
    } catch (error: any) {
      console.error(error);
      this.toastr.error('Error creating retry task', 'Error');
    }
  }

  @Action(UpdateDataGenerator)
  async updateDataGenerator(
    { getState, patchState }: StateContext<DataGeneratorStateModel>,
    { generator }: UpdateDataGenerator) {

    try {
      // Get current generator to compare changes
      const currentGenerators = getState().dataGenerators;
      const currentGenerator = currentGenerators.find(
        g => g.news_summariser_id === generator.news_summariser_id
      );

      if (!currentGenerator) {
        throw new Error('Generator not found');
      }

      // Prepare update payload
      const updatePayload: any = {
        name: generator.name,
        description: generator.description,
        runFreq: generator.runFreq
      };

      // Only add tags if user is admin
      const isAdmin = this.store.selectSnapshot(UserState.isAdmin);
      if (isAdmin && generator.tags) {
        updatePayload.tags = generator.tags;
      }

      // Update generator document
      const docRef = doc(this.db, 'data_generators', generator.news_summariser_id);
      await updateDoc(docRef, updatePayload);

      // Update data field in Firestore
      const dataFieldRef = collection(this.db, 'datafields');
      const q = query(
        dataFieldRef,
        where('news_summariser_id', '==', generator.news_summariser_id)
      );

      const snapshot = await getDocs(q);
      if (!snapshot.empty) {
        const docRef = snapshot.docs[0].ref;
        await updateDoc(docRef, updatePayload);
      }

      // Update local state
      const updatedGenerators = currentGenerators.map(g =>
        g.news_summariser_id === generator.news_summariser_id
          ? { ...g, ...updatePayload }
          : g
      );

      patchState({
        dataGenerators: updatedGenerators
      });

      this.toastr.success('Data Generator updated successfully', 'Success');
    } catch (error: any) {
      console.error('Update error:', error);
      this.toastr.error(error.message || 'Error updating data generator', 'Error');
      throw error;
    }
  }
}
