import { Component, Input } from '@angular/core';
import { DataGenerator, NewsSummaryStatus } from '../../stores/data-generator/data-generator.model';

@Component({
  selector: 'app-data-generator-status',
  templateUrl: './data-generator-status.component.html',
  styleUrls: ['./data-generator-status.component.scss']
})
export class DataGeneratorStatusComponent {
  @Input() generator!: DataGenerator;
  @Input() isLoading: boolean = false;

  statusEnum = NewsSummaryStatus;

  get currentStatus() {
    return this.generator?.status ?? NewsSummaryStatus.unavailable;
  }

  get statusTooltip() {
    return this.getStatusTooltip(this.currentStatus);
  }

  private getStatusTooltip(status: NewsSummaryStatus): string {
    switch (status) {
      case NewsSummaryStatus.queued:
        return 'Prediction queued';
      case NewsSummaryStatus.running:
        return 'Prediction is running';
      case NewsSummaryStatus.interrupted:
        return 'Prediction interrupted';
      case NewsSummaryStatus.ready:
        return 'Prediction ready';
      case NewsSummaryStatus.error:
        return 'Prediction encountered an error';
      default:
        return 'Status unavailable';
    }
  }
}
