import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngxs/store';
import { Subject, takeUntil } from 'rxjs';

import { GaSymbol } from '../../../ticker/stores/symbol/symbol.model';
import { DataItemState, LoadDataItems } from '@core/stores/data-item/data-item.state';

@Component({
  selector: 'app-data-item-list',
  templateUrl: './data-item-list.component.html',
  styleUrl: './data-item-list.component.scss',
})
export class DataItemListComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() symbol!: GaSymbol;

  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = [
    'name',
    'description',
    'backtestable',
    'actions',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  @Output() onInsert = new EventEmitter<any>();
  private destroy$ = new Subject<void>();

  constructor(private store: Store) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['symbol'] && this.symbol?.ticker) {
      this.loadDataItems();
    }
  }

  ngAfterViewInit() {
    if (this.symbol?.ticker) {
      this.loadDataItems();
    }
  }

  private loadDataItems() {
    // Dispatch the action to load data items from the store
    this.store.dispatch(new LoadDataItems(this.symbol));

    // Subscribe to the selector to update the table when the data items for the symbol are loaded
    this.store.select(DataItemState.getDataItemsBySymbol(this.symbol.ticker))
      .pipe(takeUntil(this.destroy$))
      .subscribe((items) => {
        this.dataSource = new MatTableDataSource<any>(items);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
