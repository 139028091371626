import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DataGeneratorOverviewComponent } from './pages/data-generator-overview/data-generator-overview.component';
import { DataGeneratorEditingV2Component } from './pages/data-generator-editing-v2/data-generator-editing-v2.component';
import { DataGeneratorViewComponent } from './pages/data-generator-view/data-generator-view.component';


const CHILD_ROUTES = [
  {
    path: 'list',
    component: DataGeneratorOverviewComponent,
    data: { title: 'Asset Data Generators' }
  },
  {
    path: 'create',
    component: DataGeneratorEditingV2Component,
    data: { title: 'Create Asset Data Generator' }
  },
  {
    path: 'view/:summarizerId',
    component: DataGeneratorViewComponent,
    data: { title: 'View Asset Data Generator' }
  },
  {
    path: 'edit/:summarizerId',
    component: DataGeneratorEditingV2Component,
    data: { title: 'Edit Asset Data Generator' }
  },
  {
    path: 'clone/:summarizerId',
    component: DataGeneratorEditingV2Component,
    data: { title: 'Clone Asset Data Generator' }
  }
]


const routes: Routes = [
  // Default route
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full'
  },

  // Base routes
  ...CHILD_ROUTES,

  // Asset routes (stock/crypto)
  {
    path: 'stock/:symbol',
    children: CHILD_ROUTES
  },
  {
    path: 'crypto/:symbol',
    children: CHILD_ROUTES
  },

  // Topic routes
  {
    path: 'topic/:name',
    children: CHILD_ROUTES
  },

  // Fallback route
  {
    path: '**',
    redirectTo: 'list'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DataGeneratorRoutingModule { }
