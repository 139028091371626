<app-data-generator-breadcrumb></app-data-generator-breadcrumb>
<div class="editing-container">
  <h1 class="page-title">Summarizer Configuration</h1>
  <br>

  <form [formGroup]="generatorForm" class="generator-form">
    <div class="form-section">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Summarizer Name</mat-label>
        <input matInput formControlName="name" placeholder="Enter summarizer name">
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" placeholder="Enter description"></textarea>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Earliest Run Date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="startedAt" [readonly]="mode === 'edit'">
        <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="mode === 'edit'"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <div class="cron-editor">
        <label>Run Frequency</label>
        <cron-editor
          formControlName="runFreq"
          [options]="cronOptions"
          [disabled]="false">
        </cron-editor>
      </div>

      <mat-form-field appearance="outline" class="full-width" *ngIf="isAdmin$ | async">
        <mat-label>Tags</mat-label>
        <mat-chip-grid #chipGrid>
          <mat-chip-row
            *ngFor="let tag of generatorForm.get('tags')?.value"
            (removed)="removeTag(tag)">
            {{tag}}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        </mat-chip-grid>
        <input
          placeholder="New tag..."
          [matChipInputFor]="chipGrid"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="addTag($event)">
      </mat-form-field>
    </div>

    <div class="inputs-section" *ngIf="symbol">
      <div class="section-header" (click)="toggleInputs()">
        <mat-icon>{{ showInputs ? 'expand_more' : 'chevron_right' }}</mat-icon>
        <h3>Available Inputs</h3>
      </div>

      <div class="inputs-content" *ngIf="showInputs">
        <app-data-item-list [symbol]="symbol"></app-data-item-list>
      </div>
    </div>

    <div class="prompt-section">
      <label>Prompt Body</label>
      <textarea
        class="prompt-textarea"
        formControlName="promptBody"
        placeholder="Enter prompt body"
        cdkTextareaAutosize
        cdkAutosizeMinRows="10"
        [readonly]="mode === 'edit'">
      </textarea>
    </div>

    <div class="button-container">
      <button mat-raised-button color="basic" (click)="onCancel()">Cancel</button>
      <button mat-raised-button
              color="warn"
              *ngIf="mode === 'edit'"
              (click)="onDelete()">
        Delete
      </button>
      <button mat-raised-button
              color="basic"
              *ngIf="mode === 'edit'"
              (click)="onClone()">
        Clone
      </button>
      <button mat-raised-button
              color="primary"
              (click)="onSave()"
              [disabled]="!generatorForm.valid">
        {{saveButtonLabel}}
      </button>
    </div>
  </form>
</div>
