<nav class="breadcrumb-container">
  <ol class="breadcrumb-list">
    <li *ngFor="let breadcrumb of (breadcrumbs$ | async); let last = last" class="breadcrumb-item">
      <a [routerLink]="breadcrumb.url" [class.active]="last">
        {{ breadcrumb.label }}
      </a>
      <span class="separator" *ngIf="!last">/</span>
    </li>
  </ol>
</nav>
