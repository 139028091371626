import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@core/material.module';

import { NotAuthorizedComponent } from './pages/not-authorized/not-authorized.component';
import { LegalRoutingModule } from './legal-routing.module';
import { ConsentDialogComponent } from './dialogs/consent-dialog/consent-dialog.component';

@NgModule({
  declarations: [
    NotAuthorizedComponent,
    ConsentDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    LegalRoutingModule
  ]
})
export class LegalModule { }
