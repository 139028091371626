import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../core/material.module';
import { firebaseUiAuthConfig } from '../auth.config';
import { FirebaseUIModule } from 'firebaseui-angular';

import { LandingComponent } from './pages/landing/landing.component';
import { ConsultingPageComponent } from './pages/consulting/consulting.component';
import { ProductPageComponent } from './pages/product/product.component';
import { FeedbackCannyComponent } from './pages/feedback/feedback-canny.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { LegalModule } from './legal/legal.module';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { NewsOverviewComponent } from './components/news-overview/news-overview.component';
import { MarketOverviewComponent } from './components/market-overview/market-overview.component';
import { SearchResultItemComponent } from './components/search-result-item/search-result-item.component';
import { SearchComponent } from './components/search/search.component';
import { GaHomeComponent } from './pages/ga-home/ga-home.component';
import { GaHelpComponent } from './pages/ga-help/ga-help.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { DataGeneratorModule } from '../data-generator/data-generator.module';

@NgModule({
  declarations: [
    LandingComponent,
    ConsultingPageComponent,
    ProductPageComponent,
    FeedbackCannyComponent,
    GaHelpComponent,
    GaHomeComponent,
    LoginPageComponent,

    HeaderComponent,
    FooterComponent,
    LoginComponent,

    SearchBarComponent,
    SearchResultItemComponent,
    SearchComponent,
    MarketOverviewComponent,
    NewsOverviewComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    LegalModule,
    DataGeneratorModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig)
  ],
  exports: [
    HeaderComponent,
    FooterComponent
  ]
})
export class HomeModule { }
