<app-data-generator-breadcrumb></app-data-generator-breadcrumb>
<ng-container *ngIf="generator$ | async as generator">
  <div class="generator-view">
    <header class="generator-header">
      <h2>Summarizer</h2>
    </header>

    <main class="generator-content">
      <app-data-generator-detail [generator]="generator"></app-data-generator-detail>
    </main>
  </div>
</ng-container>
