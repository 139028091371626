import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';

import { Logout, UserState } from '../../../core/stores/user/user.state';
import { Subject, takeUntil } from 'rxjs';
import { GaUser } from '@core/stores/user/user-profile.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  isAdmin$ = this.store.select(UserState.isAdmin);
  user: GaUser = {} as GaUser;

  constructor(private store: Store) {}

  ngOnInit() {
    this.store.select(UserState.user)
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        this.user = user;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  logout() {
    this.store.dispatch(new Logout());
  }
}
