import { Selector, createSelector } from '@ngxs/store';

import { DataGenerator } from './data-generator.model';
import { DataGeneratorState, DataGeneratorStateModel } from './data-generator.state';

export class DataGeneratorSelectors {
  @Selector([DataGeneratorState])
  static selectAll(state: DataGeneratorStateModel) {
    return state.dataGenerators;
  }

  static selectBySymbol(symbol: string) {
    return createSelector([DataGeneratorState], (state: DataGeneratorStateModel) => {
      return state.dataGenerators
        .filter((g: DataGenerator) => g.tags?.includes(`symbol:${symbol}`) ?? false)
        .sort((a: DataGenerator, b: DataGenerator) => {
          return a.news_summariser_id.localeCompare(b.news_summariser_id);
        });
    });
  }

  static selectByTag(tagContent: string) {
    return createSelector([DataGeneratorState], (state: DataGeneratorStateModel) => {
      return state.dataGenerators
        .filter((g: DataGenerator) => g.tags?.some(tag => tag.includes(tagContent)) ?? false)
        .sort((a: DataGenerator, b: DataGenerator) => {
          return a.news_summariser_id.localeCompare(b.news_summariser_id);
        });
    });
  }

  static selectById(id: string) {
    return createSelector([DataGeneratorState], (state: DataGeneratorStateModel) => {
      return state.dataGenerators.find((g: DataGenerator) => g.news_summariser_id === id);
    });
  }

  @Selector([DataGeneratorState])
  static isCreating(state: DataGeneratorStateModel) {
    return state.isCreating;
  }
}
