import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';
import { DataGenerator } from '../../stores/data-generator/data-generator.model';
import { DeleteDataGenerator, RetryDataGenerator } from '../../stores/data-generator/data-generator.action';
import { ConfirmDialogComponent } from '../../../core/components/confirmation-dialog/confirmation-dialog.component';
import { UserState } from '@core/stores/user/user.state';

@Component({
  selector: 'app-data-generator-menu',
  templateUrl: './data-generator-menu.component.html',
  styleUrls: ['./data-generator-menu.component.scss']
})
export class DataGeneratorMenuComponent {
  @Input() generator!: DataGenerator;
  @Input() isAdmin: boolean | null = false;

  isAdmin$ = this.store.select(UserState.isAdmin);

  get id() {
    return this.generator?.news_summariser_id;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private dialog: MatDialog)
  {}

  private getBasePath(): string {
    const url = this.router.url;

    // Check for asset-specific paths
    if (url.includes('/stock/')) {
      return `/summarizers/stock/${this.route.snapshot.params['symbol']}`;
    }
    if (url.includes('/crypto/')) {
      return `/summarizers/crypto/${this.route.snapshot.params['symbol']}`;
    }
    if (url.includes('/topic/')) {
      return `/summarizers/topic/${this.route.snapshot.params['name']}`;
    }

    // Default path
    return '/summarizers';
  }

  viewGenerator() {
    if (this.generator) {
      this.router.navigate([`${this.getBasePath()}/view`, this.id]);
    }
  }

  cloneGenerator() {
    if (this.generator) {
      this.router.navigate([`${this.getBasePath()}/clone`, this.id]);
    }
  }

  editGenerator() {
    if (this.generator) {
      this.router.navigate([`${this.getBasePath()}/edit`, this.id]);
    }
  }

  deleteGenerator() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        title: 'Delete Summarizer',
        message: 'Are you sure you want to delete this summarizer?',
        confirmText: 'Delete',
        cancelText: 'Cancel'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new DeleteDataGenerator(this.id));
      }
    });
  }

  retryGenerator() {
    if (this.generator) {
      this.store.dispatch(new RetryDataGenerator(this.id));
    }
  }

  isViewRoute(): boolean {
    return this.router.url.includes(`/view/${this.id}`);
  }
}
