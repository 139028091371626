<footer class="footer">
  <div class="container">
    <div class="logo">GPT Analyst</div>
    <div class="disclaimer">
      <p>
        The GPT Financial Analyst provides general financial analysis and insights based on historical and real-time
        market data through AI and machine learning models. These insights are for informational purposes only and do
        not constitute personalized financial advice, recommendations, or assurances of accuracy. Users are encouraged
        to exercise independent judgment and consult licensed financial professionals for advice tailored to their
        specific financial needs.
      </p>
      <p>
        Before you opt to invest in financial instruments or cryptocurrencies, it is critical to thoroughly understand
        the associated risks and costs. You should evaluate your investment goals, experience level,
        and risk tolerance carefully, and consider seeking advice from financial experts if necessary.
      </p>
      <p>By using the GPT Financial Analyst, users acknowledge and agree that:
      </p>
      <ul>
        <li>The analysis and data provided may not be complete, accurate, or reflective of real-time market
          conditions. Data sources may include market makers rather than direct market feeds, and values are indicative
          rather than actionable.</li>
        <li>The use of AI-driven models may result in varying interpretations and conclusions based on historical data
          that do not account for individual circumstances, risk tolerance, or investment objectives.</li>
        <li>All investments carry risk, including the potential loss of principal. Users should review our
          comprehensive Risk Warning before making any investment decisions. This Risk Warning details the specific
          risks associated with trading financial instruments, including leveraged and speculative products such as
          futures, options, and FX.</li>
      </ul>
      <p>
        <b>Intellectual Property and Use Restrictions: </b>
        All content, data, and insights generated by the GPT Financial Analyst are protected by intellectual property
        rights. Unauthorized use, reproduction, distribution, or modification without express written permission is
        prohibited.
      </p>
      <p><b>Advertising Disclosure: </b>GPT Financial Analyst may display third-party advertisements, for which it may receive compensation. This
        compensation does not influence the neutrality of the financial insights provided.
      </p>
      <p><b>Limitations of Liability: </b>The GPT Financial Analyst and its data providers assume no responsibility for losses or damages arising from
        reliance on information or analysis provided. Use of this service is at the user's own risk.
      </p>
      <p>By using this service, you agree to our Terms and Conditions, Privacy Policy, and Risk Warning.

      </p>
    </div>
    <div class="copyright">
      <div class="operated">
        <div>LogicFlow AG</div>
        <div>Butzenstrasse 130</div>
        <div>CH-8041 Zürich</div>
        <div>VAT No.CHE-445.455.310 MWST</div>
        <div>&copy; 2024 - All Rights Reserved.</div>
        <br>
      </div>
      <div class="conditions">
        <a onclick="cookieconsent.openPreferencesCenter();">Cookie preferences</a>
        <a routerLink="/terms-and-conditions">Terms And Conditions</a>
        <a routerLink="/privacy-policy">Privacy Policy</a>
        <a routerLink="/risk-warning">Risk Warning</a>
      </div>
    </div>
  </div>
</footer>
