import { Component, EventEmitter, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Subscription } from 'rxjs';

import { DataGenerator, NewsSummaryStatus } from '../../stores/data-generator/data-generator.model';
import { DeleteDataGenerator, RetryDataGenerator } from '../../stores/data-generator/data-generator.action';
import { Prediction } from '../../../core/stores/prediction/prediction.model';
import { PredictionSelectors } from '../../../core/stores/prediction/prediction.selector';
import { PredictionBack, PredictionNext, PredictionSelect } from '../../../core/stores/prediction/prediction.state';
import { UserState } from '../../../core/stores/user/user.state';


@Component({
  selector: 'app-data-generator-detail',
  templateUrl: './data-generator-detail.component.html',
  styleUrls: ['./data-generator-detail.component.scss'],
})
export class DataGeneratorDetailComponent implements OnInit, OnDestroy, OnChanges {
  @Input() generator!: DataGenerator;
  @Input() showPromptDetails: boolean = true;
  @Output() clone = new EventEmitter<DataGenerator>();

  isAdmin$ = this.store.select(UserState.isAdmin);
  isOwner$ = this.isAdmin$.pipe(
    map((isAdmin) => {
      return isAdmin || this.generator.isOwner;
    })
  );

  get id() {
    return this.generator.news_summariser_id;
  }

  get currentStatus() {
    return this.generator?.status ?? NewsSummaryStatus.unavailable;
  }

  get statusTooltip() {
    return this.getStatusTooltip(this.currentStatus);
  }

  get cost() {
    const p = this.currentPrediction;
    const tokens = (p?.input_tokens ?? 0) + (p?.output_tokens ?? 0);
    const words = (tokens * 0.75).toFixed(0);
    const workHours = (60 * tokens / 2000).toFixed(0);
    return `Processed ${words} words ~ ${workHours}min of work`;
  }

  get cumulativeCost() {
    const g = this.generator;
    const cumulativeTokens = (g.inputTokens ?? 0) + (g.outputTokens ?? 0);
    const cumulativeWords = cumulativeTokens * 0.75;
    const cumulativeHours = (cumulativeTokens / 2000).toFixed(0);

    // Scale the tokens
    let scaledWords: string;
    if (cumulativeWords >= 1_000_000) {
      scaledWords = `${(cumulativeWords / 1_000_000).toFixed(1)}m`;
    } else if (cumulativeWords >= 1_000) {
      scaledWords = `${(cumulativeWords / 1_000).toFixed(1)}k`;
    } else {
      scaledWords = `${cumulativeWords}`;
    }

    return `(Processed ${scaledWords} words ~ ${cumulativeHours}h of work)`;
  }

  get costToolTip() {
    const isAdmin = this.store.selectSnapshot(UserState.isAdmin);
    if (!isAdmin) {
      return 'This is an estimate of the cost of the prediction. The actual cost may vary.';
    }

    const p = this.currentPrediction;
    const cost = (p?.cost ?? 0).toFixed(3);
    const cumulativeCost = (this.generator?.cost ?? 0).toFixed(3);
    return `This is an estimate. ${cost} USD. Cumulative cost: ${cumulativeCost} USD.`;
  }

  currentPrediction!: Prediction | null;
  isFirst: boolean = false;
  isLast: boolean = false;
  isLoading: boolean = false;
  statusEnum = NewsSummaryStatus;

  private subscriptions: Subscription = new Subscription();

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.setupSubscriptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['generator'] && !changes['generator'].firstChange) {
      // Clean up existing subscriptions and set up new ones
      this.subscriptions.unsubscribe();
      this.subscriptions = new Subscription();
      this.setupSubscriptions();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setupSubscriptions(): void {
    const id = this.generator.news_summariser_id;

    this.subscriptions.add(
      this.store
        .select(PredictionSelectors.selectedForGenerator(id))
        .subscribe((prediction) => (this.currentPrediction = prediction))
    );

    this.subscriptions.add(
      this.store
        .select(PredictionSelectors.isFirstPrediction(id))
        .subscribe((isFirst) => (this.isFirst = isFirst))
    );

    this.subscriptions.add(
      this.store
        .select(PredictionSelectors.isLastPrediction(id))
        .subscribe((isLast) => (this.isLast = isLast))
    );

    this.subscriptions.add(
      this.store
        .select(PredictionSelectors.isLoading(id))
        .subscribe((isLoading) => (this.isLoading = isLoading))
    );
  }

  previous() {
    this.store.dispatch(new PredictionBack(this.id));
  }

  next() {
    this.store.dispatch(new PredictionNext(this.id));
  }

  select(date: Date) {
    this.store.dispatch(new PredictionSelect(this.id, date));
  }


  deleteGenerator() {
    this.store.dispatch(new DeleteDataGenerator(this.id));
  }

  cloneGenerator() {
    this.clone.emit(this.generator);
  }

  retryGenerator() {
    this.store.dispatch(new RetryDataGenerator(this.generator.news_summariser_id));
  }

  private getStatusTooltip(status: NewsSummaryStatus): string {
    switch (status) {
    case NewsSummaryStatus.queued:
      return 'Prediction queued';
    case NewsSummaryStatus.running:
      return 'Prediction is running';
    case NewsSummaryStatus.interrupted:
      return 'Prediction interrupted';
    case NewsSummaryStatus.ready:
      return 'Prediction ready';
    case NewsSummaryStatus.error:
      return 'Prediction encountered an error';
    default:
      return 'Status unavailable';
    }
  }
}
