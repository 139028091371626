import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CronEditorModule } from 'ngx-cron-editor';
import { MaterialModule } from '../core/material.module';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MarkdownModule } from 'ngx-markdown';

import { DataGeneratorMenuComponent } from './components/data-generator-menu/data-generator-menu.component';
import { DataGeneratorStatusComponent } from './components/data-generator-status/data-generator-status.component';
import { DataGeneratorCostComponent } from './components/data-generator-cost/data-generator-cost.component';
import { DataGeneratorDetailComponent } from './components/data-generator-detail/data-generator-detail.component';
import { DataGeneratorViewComponent } from './pages/data-generator-view/data-generator-view.component';
import { DataGeneratorEditingComponent } from './dialogs/data-generator-editing/data-generator-editing.component';
import { DataGeneratorTimelineComponent } from './components/data-generator-timeline/data-generator-timeline.component';
import { DataGeneratorItemsComponent } from './components/data-generator-items/data-generator-items.component';
import { DataGeneratorOverviewComponent } from './pages/data-generator-overview/data-generator-overview.component';
import { DataGeneratorEditingV2Component } from './pages/data-generator-editing-v2/data-generator-editing-v2.component';
import { DataGeneratorListComponent } from './components/data-generator-list/data-generator-list.component';
import { DataGeneratorState } from './stores/data-generator/data-generator.state';
import { NewsTimelineState } from './stores/news-timeline/news-timeline.state';
import { DataGeneratorRoutingModule } from './data-generator-routing.module';
import { DataGeneratorBreadcrumbComponent } from './components/data-generator-breadcrumb/data-generator-breadcrumb.component';
import { DataGeneratorPromptDetailsComponent } from './components/data-generator-prompt-details/data-generator-prompt-details.component';
import { DataGeneratorHomeComponent } from './components/data-generator-home/data-generator-home.component';
import { GaCoreModule } from '@core/core.module';

@NgModule({
  declarations: [
    DataGeneratorMenuComponent,
    DataGeneratorStatusComponent,
    DataGeneratorCostComponent,
    DataGeneratorViewComponent,
    DataGeneratorDetailComponent,
    DataGeneratorEditingComponent,
    DataGeneratorTimelineComponent,
    DataGeneratorItemsComponent,
    DataGeneratorOverviewComponent,
    DataGeneratorEditingV2Component,
    DataGeneratorListComponent,
    DataGeneratorBreadcrumbComponent,
    DataGeneratorPromptDetailsComponent,
    DataGeneratorHomeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    CronEditorModule,
    RouterModule,
    DataGeneratorRoutingModule,
    GaCoreModule,
    TextFieldModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MarkdownModule.forRoot(),
    NgxsModule.forFeature([
      DataGeneratorState,
      NewsTimelineState
    ])
  ],
  exports: [
    DataGeneratorOverviewComponent,
    DataGeneratorViewComponent,
    DataGeneratorListComponent,
    DataGeneratorHomeComponent
  ]
})
export class DataGeneratorModule { }
