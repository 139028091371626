<h2>Getting started</h2>
<p>Watch a quick video to see how to create your first prompt.</p>
<div style="display: flex; justify-content: center; align-items: center; padding: 1%;">
<div style="overflow: hidden; aspect-ratio: 1920/1080; width: 60%;"><iframe src="https://share.synthesia.io/embeds/videos/752abe9d-d4fd-4d97-8598-8732241e3a6a" loading="lazy" title="Synthesia video player - GPT Analyst: How to write a prompt" allowfullscreen allow="encrypted-media; fullscreen;" style="display: flex; width: 100%; height: 100%; padding: 0; margin: 0; overflow:hidden;"></iframe></div>
</div>

<h2 id="private-data" style="padding-top: 24px;">Private Data</h2>
GPT Analyst provides access to some basic data for stocks and cryptos, but maybe you have your own data you'd like to include in your prompts. If that's the case, we've got you covered.
Navigate to your <a routerLink="/profile" fragment="user-data">profile</a> and click on the "+" button in "My Data Sources" to create a new data set.<br>
GPT Analyst supports static data and time-series data. The data has to be provided as a JSON file with specific format depending on the type of data:<br><br>
<mat-expansion-panel hideToggle>
  <mat-expansion-panel-header>
    <mat-panel-title>Static data</mat-panel-title>
  </mat-expansion-panel-header>
  <p>Static data is good for describing companies, stocks other other quantities you care about. As its name entails, it is assumed to be constant through all time. It is important when using such data to ensure that you do not provide future information as a side effect.</p>
  <p>The formatting for static data should follow this format:</p>
  <div style="padding-left: 20px; background-color: lightgray; padding-top: 5px; padding-bottom: 5px;">
    <code>
      &#123;<br>
        &nbsp;&nbsp;"string_field": "value",<br>
        &nbsp;&nbsp;"number_field": 42,<br>
        &nbsp;&nbsp;"array_field": [1, 2, 3]<br>
      &#125;<br>
    </code>
  </div>
</mat-expansion-panel>
<mat-expansion-panel hideToggle>
  <mat-expansion-panel-header>
    <mat-panel-title>Time-series data</mat-panel-title>
  </mat-expansion-panel-header>
  <p>Time-series data is used to model values which change over time and is the most useful type of data when using GPT Analyst. The formatting is similar to the one for static data, with two key differences:</p>
  <ul>
    <li>The data needs to be a list</li>
    <li>Each entry needs to have a <i>valid_at</i> field indicating the earliest time that the entry is valid at. This field <b>must</b> be a timezone aware timestamp in ISO 8601 format.</li>
  </ul>

  <p>We strongly recommend that each list entry contains the same field names. Varying of missing fields are likely to cause errors during prompt injection.</p>

  <p>The formatting for time-series data should follow this format:</p>
  <div style="padding-left: 20px; background-color: lightgray; padding-top: 5px; padding-bottom: 5px;">
    <pre style="white-space: pre-line;">
      <code>
        &#91;
        &nbsp;&nbsp;&#123;
            &nbsp;&nbsp;&nbsp;&nbsp;<b>"valid_at"</b>: "2021-01-01T00:00:00Z",
            &nbsp;&nbsp;&nbsp;&nbsp;"string_field": "value",
            &nbsp;&nbsp;&nbsp;&nbsp;"number_field": 42,
            &nbsp;&nbsp;&nbsp;&nbsp;"array_field": [1, 2, 3]
          &nbsp;&nbsp;&#125;,
          &nbsp;&nbsp;&#123;
            &nbsp;&nbsp;&nbsp;&nbsp;<b>"valid_at"</b>: "2021-01-02T00:00:00Z",
            &nbsp;&nbsp;&nbsp;&nbsp;"string_field": "value",
            &nbsp;&nbsp;&nbsp;&nbsp;"number_field": 42,
            &nbsp;&nbsp;&nbsp;&nbsp;"array_field": [1, 2, 3]
          &nbsp;&nbsp;&#125;,
          &nbsp;&nbsp;&#123;
            &nbsp;&nbsp;&nbsp;&nbsp;<b>"valid_at"</b>: "2021-01-03T00:00:00Z",
            &nbsp;&nbsp;&nbsp;&nbsp;"string_field": "value",
            &nbsp;&nbsp;&nbsp;&nbsp;"number_field": 42,
            &nbsp;&nbsp;&nbsp;&nbsp;"array_field": [1, 2, 3]
          &nbsp;&nbsp;&#125;,
          &nbsp;&nbsp;...
        &#93;
      </code>
    </pre>
  </div>
  </mat-expansion-panel>

<h2 style="padding-top: 24px;">Frequently Asked Questions</h2>
<div>
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>How is prompt performance calculated?</mat-panel-title>
    </mat-expansion-panel-header>
    <p>Prompt performance is computed by simulating a trade event on the next available market open / close. That means that e.g. a prompt for a symbol traded on the NSYE evaluated at 2pm on a Monday afternoon will be executed at 4 pm that day. If the prompt is evaluated after market close, it will be traded on open the next day.</p>
    <br>
    <p>This <i>also</i> means that a prompt evaluated after market close on a Friday evening will not be traded until the next Monday morning. Should additional prompt evaluations occur prior to the next open (e.g. on Saturday and Sunday) only the last one will be executed.</p>
  </mat-expansion-panel>
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>How can I upgrade my account?</mat-panel-title>
    </mat-expansion-panel-header>
    <p>We are actively working on this functionality. Stay tuned!</p>
  </mat-expansion-panel>
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>I cannot find symbol XYZ.</mat-panel-title>
    </mat-expansion-panel-header>
    <p>Sorry about that! We are regularly adding new data sources, hopefully your favourite stock will be available soon. Head over to our <a routerLink="/feedback">contact</a> page to let us know what you're looking for.</p>
  </mat-expansion-panel>
</div>
