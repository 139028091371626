import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { DataGenerator } from '../../stores/data-generator/data-generator.model';
import { LoadTagDataGenerators } from '../../stores/data-generator/data-generator.action';
import { DataGeneratorSelectors } from '@data-generator/stores/data-generator/data-generator.selector';

@Component({
  selector: 'app-data-generator-home',
  templateUrl: './data-generator-home.component.html',
  styleUrls: ['./data-generator-home.component.scss']
})
export class DataGeneratorHomeComponent implements OnInit {
  generators$: Observable<DataGenerator[]>;
  selectedGenerator: DataGenerator | undefined;

  constructor(private store: Store) {
    this.generators$ = this.store.select(DataGeneratorSelectors.selectByTag('home'));

    // Set selected generator to the first generator that is selected or with 'macro' in tag
    const selectedId = this.selectedGenerator?.news_summariser_id;
    this.generators$.subscribe(generators => {
      this.selectedGenerator = generators
        .find((g: DataGenerator) => g.news_summariser_id === selectedId || g.tags?.includes('macro'));
    });
  }

  ngOnInit() {
    // Load generators with 'home' tag, don't load predictions yet
    this.store.dispatch(new LoadTagDataGenerators('home', false));
  }

  onGeneratorSelect(generator: DataGenerator) {
    this.selectedGenerator = generator;
  }
}
