import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NewsNavigationService {
  currentNews: any[] = [];
  newsIndex = 0;
  itemsPerPage = 3;

  searchSectionOpened: boolean = false;
  startDate: Date = new Date();
  endDate!: Date;
  performance: string = 'Winners';
  positionChange: boolean = true;

  constructor() {
    this.startDate = this.getLastBusinessDayMidnight(new Date());
  }

  getLastBusinessDayMidnight(date: Date): Date {
    const day = date.getDay(); // Get the current day of the week (0: Sunday, 1: Monday, ..., 6: Saturday)
    const lastBusinessDay = new Date(date); // Clone the date

    if (day === 0) { // Sunday
      lastBusinessDay.setDate(date.getDate() - 2); // Go back to Friday
    } else if (day === 6) { // Saturday
      lastBusinessDay.setDate(date.getDate() - 1); // Go back to Friday
    } else if (day === 1) { // Monday
      lastBusinessDay.setDate(date.getDate() - 3); // Go back to Friday
    } else { // Any other weekday (Tuesday to Friday)
      lastBusinessDay.setDate(date.getDate() - 1); // Go back to the previous day
    }

    // Set the time to midnight
    lastBusinessDay.setHours(0, 0, 0, 0);

    return lastBusinessDay;
  }

  getTitle() {
    let title = 'News';

    if (this.startDate) {
      title = `News from ${this.startDate.toDateString()}`;
    }
    if (!this.endDate) {
      title += ` to Today`;
    }
    if (this.endDate) {
      title += ` to ${this.endDate?.toDateString()}`;
    }
    return title;
  }
}
