import { Component } from '@angular/core';
import { Store } from '@ngxs/store';

import { UserState } from '../../../core/stores/user/user.state';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss'
})
export class LandingComponent {
  isAuthenticated$ = this.store.select(UserState.isAuthenticated);

  constructor(private store: Store) { }
}
