import { collection, Firestore, limit, query, where } from "@angular/fire/firestore";

// Define the Symbol interface
export interface GaSymbol {
  readonly ticker: string;
  readonly class: string;
  readonly priceField: string;
  readonly priceField2: string;
  readonly routePath: string[];
  readonly fullTicker: string;
  getAdjustedPriceQuery(): any;
}

// Factory function to create the correct instance
export function createSymbol(assetClass: string, ticker: string, db: Firestore): GaSymbol {
  if (assetClass === 'crypto') {
    return new CryptoSymbol(ticker, db);
  } else if (assetClass === 'stock') {
    return new StockSymbol(ticker, db);
  } else {
    throw new Error(`Invalid asset class: ${assetClass}`);
  }
}

// Implementation for CryptoSymbol
export class CryptoSymbol implements GaSymbol {
  readonly db: Firestore;
  readonly ticker: string;
  readonly class = 'crypto';
  readonly priceField = 'close';
  readonly priceField2 = 'close';
  readonly routePath: string[];

  get fullTicker(): string {
    return `${this.class}:${this.ticker}`;
  }

  constructor(ticker: string, db: Firestore) {
    this.db = db;
    this.ticker = ticker;
    this.routePath = ['tickers', 'crypto', ticker];
  }

  getAdjustedPriceQuery(): any {
    const endTime = new Date();
    endTime.setUTCDate(endTime.getUTCDate() - 1);
    endTime.setUTCHours(23, 59, 59, 999);
    console.log("Loading timestamp", endTime.getTime());

    const adjusted = query(
      collection(this.db, 'data'),
      where("symbol", "==", this.ticker),
      where("metadata.endpoint", "==", "klines"),
      where("endTime", "==", endTime.getTime()),
      limit(1)
    );

    return adjusted;
  }
}

// Implementation for StockSymbol
export class StockSymbol implements GaSymbol {
  readonly db: Firestore;
  readonly ticker: string;
  readonly class = 'stock';
  readonly priceField = '5. adjusted close';
  readonly priceField2 = '5.+adjusted+close';
  readonly routePath: string[];

  get fullTicker(): string {
    return `${this.class}:${this.ticker}`;
  }

  constructor(ticker: string, db: Firestore) {
    this.db = db;
    this.ticker = ticker;
    this.routePath = ['tickers', 'stock', ticker];
  }

  getAdjustedPriceQuery(): any {
    const adjusted = query(
      collection(this.db, 'data'),
      where("symbol", "==", this.ticker),
      where("function", "==", "TIME_SERIES_DAILY_ADJUSTED"),
      where("_split", "==", "column"),
      where("outputsize", "==", "full"),
      limit(1)
    );

    return adjusted;
  }
}
