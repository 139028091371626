import { Component, Input } from '@angular/core';
import { DataGenerator } from '../../stores/data-generator/data-generator.model';

@Component({
  selector: 'app-data-generator-cost',
  templateUrl: './data-generator-cost.component.html',
  styleUrls: ['./data-generator-cost.component.scss']
})
export class DataGeneratorCostComponent {
  @Input() generator!: DataGenerator;
  @Input() isAdmin: boolean | null = false;

  get cumulativeCost() {
    const cumulativeTokens = (this.generator.inputTokens ?? 0) + (this.generator.outputTokens ?? 0);
    const cumulativeWords = cumulativeTokens * 0.75;
    const cumulativeHours = (cumulativeTokens / 2000).toFixed(0);

    let scaledWords: string;
    if (cumulativeWords >= 1_000_000) {
      scaledWords = `${(cumulativeWords / 1_000_000).toFixed(1)}m`;
    } else if (cumulativeWords >= 1_000) {
      scaledWords = `${(cumulativeWords / 1_000).toFixed(1)}k`;
    } else {
      scaledWords = `${cumulativeWords}`;
    }

    return `(Processed ${scaledWords} words ~ ${cumulativeHours}h of work)`;
  }

  get costTooltip() {
    if (!this.isAdmin) {
      return 'This is an estimate of the cost of the prediction. The actual cost may vary.';
    }

    const cost = (this.generator?.cost ?? 0).toFixed(3);
    return `This is an estimate. Cumulative cost: ${cost} USD.`;
  }
}
