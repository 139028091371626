import { Component, Input } from '@angular/core';
import { getSentimentColor } from '../data-generator-timeline/data-generator-timeline.component';
import { Store } from '@ngxs/store';
import { NewsTimelineState } from '../../stores/news-timeline/news-timeline.state';
import { Prediction } from '@core/stores/prediction/prediction.model';

@Component({
  selector: 'app-data-generator-items',
  templateUrl: './data-generator-items.component.html',
  styleUrls: ['./data-generator-items.component.scss']
})
export class DataGeneratorItemsComponent {
  items: any[] = [];
  _prediction: Prediction | null = null;
  _mapping: any = {};

  @Input()
  set summarizerId(id: string) {
    this.updateDimensions(id);
  }

  @Input()
  set prediction(prediction: Prediction | null) {
    this._prediction = prediction;
    if (prediction) {
      this.items = this.parseSummaryToJson(prediction.summary);
      this.mapDimension();
    } else {
      this.items = [];
    }
  }

  get itemLength(): number {
    return this.items?.length ?? 0;
  }

  constructor(private store: Store) {}

  private updateDimensions(newsSummarizerId: string) {
    // Update items when timeline name mappings changes
    this.store
      .select(NewsTimelineState.getNameMappingsById(newsSummarizerId))
      .subscribe((mapping) => {
        this._mapping = mapping;
        this.mapDimension();
      });
  }

  private mapDimension(): void {
    if (!this._mapping) return;

    this.items = this.items.map((item) => {
      let name = item.name;
      const dimension = this._mapping[name];
      if (!dimension) return item;
      if (dimension == name) {
        name = 'Overall';
      }

      return {
        ...item,
        dimension,
        name
      };
    }).sort((a, b) => a.dimension.localeCompare(b.dimension));
  }

  private parseSummaryToJson(summaryText: string): Array<{
    startDate: Date;
    name: string;
    sentiment: string;
    summary: string;
  }> {
    /**
     * Parse the summary into the desired JSON format.
     *
     * Expected format:
     * - **YYYY-MM-DD** Dimension: Sentiment: Summary text
     */
    const entryPattern = /- \*\*(\d{4}-\d{2}-\d{2})\*\* (.+?): (.+?): (.+?)(?=(?:\n- \*\*|$))/gs;

    const matches = Array.from(summaryText.matchAll(entryPattern));
    const parsedEntries: Array<{
      startDate: Date;
      name: string;
      sentiment: string;
      summary: string;
    }> = [];

    for (const match of matches) {
      try {
        const startDate = new Date(match[1].trim());
        const name = match[2].trim();
        const sentiment = match[3].trim();
        const summary = match[4].replace(/\n/g, ' ').trim();

        const parsedEntry = {
          startDate,
          dimension: name,
          name,
          sentiment,
          summary,
          color: getSentimentColor(sentiment)
        };

        parsedEntries.push(parsedEntry);
      } catch (error) {
        console.error(`Error parsing entry: ${match} - ${error}`);
        continue;
      }
    }

    return parsedEntries;
  }

}
