import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';

import { FirebaseHttpService } from '../../../core/services/firebase-http.service';
import { Logout, UserState } from '../../../core/stores/user/user.state';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isAdmin$ = this.store.select(UserState.isAdmin);
  isAuthenticated$ = this.store.select(UserState.isAuthenticated);

  constructor(
    private store: Store,
    private router: Router,
    private fbHttps: FirebaseHttpService) {
    this.fbHttps.call('api', { method: 'init' });
  }

  home(): void {
    const currentRoute = this.router.url;

    if (currentRoute.includes('ticker')) {
      this.router.navigate(['/news']);
    } else {
      this.router.navigate(['/']);
    }
  }

  logout() {
    this.store.dispatch(new Logout());
  }
}
