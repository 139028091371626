<button mat-menu-item (click)="viewGenerator()" *ngIf="!isViewRoute()">
  <mat-icon>visibility</mat-icon>
  <span>View</span>
</button>
<button mat-menu-item (click)="cloneGenerator()">
  <mat-icon>content_copy</mat-icon>
  <span>Clone</span>
</button>
<button mat-menu-item *ngIf="generator?.isOwner || (isAdmin$ | async)" (click)="editGenerator()">
  <mat-icon>edit</mat-icon>
  <span>Edit</span>
</button>
<button mat-menu-item *ngIf="generator?.isOwner || (isAdmin$ | async)" (click)="deleteGenerator()">
  <mat-icon>delete</mat-icon>
  <span>Delete</span>
</button>
<button mat-menu-item *ngIf="isAdmin$ | async" (click)="retryGenerator()">
  <mat-icon>refresh</mat-icon>
  <span>Retry</span>
</button>
