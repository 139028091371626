import { CronOptions } from 'ngx-cron-editor';
import { DataGenerator } from './stores/data-generator/data-generator.model';

export const DEFAULT_CRON_OPTIONS: CronOptions = {
  defaultTime: "00:00",
  hideMinutesTab: false,
  hideHourlyTab: false,
  hideDailyTab: false,
  hideWeeklyTab: false,
  hideMonthlyTab: false,
  hideYearlyTab: true,
  hideAdvancedTab: false,
  hideSpecificWeekDayTab: false,
  hideSpecificMonthWeekTab: false,
  use24HourTime: true,
  hideSeconds: true,
  cronFlavor: "standard"
};

export const insertTemplateInput = (generator: DataGenerator, item: { name: string, examples: string[] }): string => {
  return `${generator.prompt.template_body}${item.name}: \n${item.examples[0]}`;
};

export const DEFAULT_PROMPT = `# INSTRUCTIONS
You are a senior financial analyst analyzing news items to identify information which may impact the profitability of a company.
When providing a summary, you are careful to date the information in order to provide context. News have a tendency of losing importance with time, but some information can impact a company's value for a long time. It is your role to ensure that the summary is as informative, quantitative and relevant as possible.

You are provided with the summary you produced previously. Make sure that your response incorporates elements from the previous summary which you think are still relevant now.

Ignore news items which provide no clear signal.

Provide your summary as bullet point list in the following format:
- **YYYY-MM-DD** Dimension: Sentiment: Summary
YYYY-MM-DD: is the initial date at which this dimension was first tracked.
Dimension: a typical financial analysis dimension relevant to the future valuation of {Name|<SYMBOL>}.
Sentiment: Very Negative, Negative, Neutral, Positive, Strongly Positive
Summary: max 100 words.

Example:
- **2019-12-30** Growth Potential: Neutral: TMO growth outlook is ...

Track a maximum of 5 dimensions. Avoid duplicate dimensions. Merge incoming news into the dimensions of the previous summary. Only replace a dimension when something more impactful happened.

If there is +++ NOT AVAILABLE +++ in the # NEWS, output the unmodified existing summary. If the existing summary is empty, output only "No news available". Nothing else.

It is currently {CurrentRunDate}. The previously summary was generated at {LastRunDate}.

# EXISTING SUMMARY
{SelfSummary}

# NEWS
{TopNews|NEW|<SYMBOL>}

# UPDATED SUMMARY`;
